import React from "react";
import { 
  Navigate, 
  createHashRouter 
  // createBrowserRouter 
} from "react-router-dom";

// import ErrorPage from "../components/common/ErrorPage";
import SetTitle from "../components/SetTitle";

const Root = React.lazy(() => import("../layout"));
const Home = SetTitle(React.lazy(() => import("../pages/home")));
const About = SetTitle(React.lazy(() => import("../pages/about/about")));
const Join = SetTitle(React.lazy(() => import("../pages/join/Join")));

const PLayout = React.lazy(() => import("../pages/product/index"));
const Product = SetTitle(React.lazy(() => import("../pages/product/product")));
const PTelegram = SetTitle(
  React.lazy(() => import("../pages/product/Telegram"))
);
const PApk = SetTitle(React.lazy(() => import("../pages/product/Apk")));
const PShengtou = SetTitle(
  React.lazy(() => import("../pages/product/Shengtou"))
);
const PXiaocheng = SetTitle(
  React.lazy(() => import("../pages/product/Xiaocheng"))
);
const PLink = SetTitle(React.lazy(() => import("../pages/product/Link")));

const Service = SetTitle(React.lazy(() => import("../pages/service/service")));
const PageNotFund = React.lazy(
  () => import("../components/common/PageNotFound")
);

export const routers = [
  {
    // 重定向首页, 入口索引页
    index: true,
    element: <Navigate to="home" />,
  },
  {
    path: "home",
    element: <Home title={"昱澄信息丨科技助力正义"} />,
    errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
    meta: {
      title: "首页",
      path: "/",
      list: [],
    },
  },
  {
    path: "product",
    element: <PLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="base" />,
      },
      {
        path: "base",
        element: <Product title="产品" />,
        errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
        meta: {
          title: "",
        },
      },
      {
        path: "link",
        element: <PLink title="链审查-虚拟币资金溯源系统" />,
        errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
        meta: {
          title: "链审查-虚拟币资金溯源系统",
        },
      },
      {
        path: "telegram",
        element: <PTelegram title="Telegram-电报舆情推送系统" />,
        errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
        meta: {
          title: "Telegram-电报舆情推送系统",
        },
      },
      {
        path: "apk_sys",
        element: <PApk title="海纳百川-APK聚类分析系统" />,
        errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
        meta: {
          title: "海纳百川-APK聚类分析系统",
        },
      },
      {
        path: "sheng_tou",
        element: <PShengtou title="昱澄哨兵-自动化渗透工具" />,
        errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
        meta: {
          title: "昱澄哨兵-自动化渗透工具",
        },
      },
      {
        path: "xiao_cheng",
        element: <PXiaocheng title="小澄智侦-资金流水穿透系统" />,
        errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
        meta: {
          title: "小澄智侦-资金流水穿透系统",
        },
      },
    ],
    errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
    meta: {
      title: "产品",
      list: [
        {
          path: "/product/base",
          url: "/product/telegram",
          base: "",
          title: "Telegram-电报舆情推送系统",
        },
        {
          path: "/product/base",
          url: "/product/link",
          base: "",
          title: "链审查-虚拟币资金溯源系统",
        },
        {
          path: "/product/base",
          url: "/product/sheng_tou",
          base: "",
          title: "昱澄哨兵-自动化渗透工具",
        },
        {
          path: "/product/base",
          url: "/product/xiao_cheng",
          base: "",
          title: "小澄智侦-资金流水穿透系统",
        },
        {
          path: "/product/base",
          url: "/product/apk_sys",
          base: "",
          title: "海纳百川-APK聚类分析系统",
        },
      ],
    },
  },
  {
    path: "service",
    element: <Service title="服务" />,
    meta: {
      title: "服务",
      path: "service",
      list: [],
    },
    errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
  },
  {
    path: "about",
    element: <About title="关于我们" />,
    errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
    meta: {
      title: "关于我们",
      list: [
        {
          path: "/about",
          url: "firmIntroduce",
          base: "/about",
          title: "公司介绍",
        },
        {
          path: "/about",
          url: "teamIntro",
          base: "/about",
          title: "团队介绍",
        },
        {
          path: "/about",
          url: "contact",
          base: "/about",
          title: "联系我们",
        },
      ],
    },
    children: [
    ],
  },
  {
    path: "join",
    element: <Join title="加入我们" />,
    meta: {
      title: "加入我们",
      list: [
        // '福利待遇',
        // '诚聘英才'

        {
          path: "/join",
          url: "fuli",
          base: "/join",
          title: "福利待遇",
        },
        {
          path: "/join",
          url: "excellence",
          base: "/join",
          title: "诚聘英才",
        },
      ],
    },
    errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
  },
];

const router = createHashRouter([
// const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
    children: [...routers],
  },
  {
    path: "*",
    element: <PageNotFund tips="" />,
    errorElement: <PageNotFund tips="oops， 发生错误啦!" />,
  },
]);

export default router;
