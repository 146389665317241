/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

const keyMap:any = {
    firmIntroduce: '公司介绍',
    excellence: '诚聘英才',
    teamIntro:  '团队介绍',
    contact: '联系我们',
    fuli: '福利待遇',
    '/product/telegram': 'Telegram-电报舆情推送系统',
    '/product/link': '链审查-虚拟币资金溯源系统',
    '/product/sheng_tou': '昱澄哨兵-自动化渗透工具',
    '/product/xiao_cheng':'小澄智侦-资金流水穿透系统',
    '/product/apk_sys': '海纳百川-APK聚类分析系统'
};
// HOC 封装网页标题
const SetTitle:any = (WrappedComponent: React.ElementType<any>) => {
    const n: React.ElementType = (props: any) => {
        const location:any = useLocation()

        useEffect(() => {
            // console.log('location-title==========================', location)
            if(props.title) {
                document.title = props.title
                let path = location.search.split('=')[1]
                if(location.search && keyMap[path]) {
                    document.title = keyMap[path]
                }
            } else {
                let path =  location.search.split('=')[1]
                // console.log('location-title==========================', path)
                document.title = keyMap[path]
            }
        }, [location, props.title])


        return <WrappedComponent {...props} />
    }
    return n
}

export default SetTitle