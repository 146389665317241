import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from 'react-router-dom'

import './styles/index.css';
import 'animate.css'
import routes from './router/index'
import './utils/res'

import Loadding from './components/common/loading/Loading';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
  );
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loadding />}>
      {/* <ErrorBoundary fallback={<div>opps, 页面出错了</div>}> */}
        <RouterProvider router={routes} />
      {/* </ErrorBoundary> */}
    </React.Suspense>
  </React.StrictMode>
);