import React from 'react'

import Styles from './loading.module.less'

interface Props {
    tips?: string
}

const Loadding: React.FC<Props> = (props) => {
    return (
        <div className={Styles['loading']}>Loading&#8230;</div>
    )
}

export default Loadding